import { useState } from "react";
import { useWebSocketApi } from "../hooks/useWebSocketApi";
import { OrderDetails } from "../Types/OrderInfoTypes/OrderTypes";

export function useQueryOrderInfo() {
  const websocket = useWebSocketApi();
  const [orderInfoResponse, setOrderInfoResponse] = useState<OrderDetails>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchOrderInfo = (inputId: string) => {
    if (isFetching || !inputId) return;

    setIsFetching(true);
    setError(null);

    websocket.connect({
      onOpen: () => {
        websocket.sendMessage(inputId);
      },
      onMessage: (event) => {
        try {
          setOrderInfoResponse(JSON.parse(event.data));
        } catch (e) {
          setError("Failed to parse WebSocket message.");
        } finally {
          setIsFetching(false);
          websocket.closeConnection();
        }
      },
      onError: () => {
        setError("WebSocket error occurred");
        setIsFetching(false);
        websocket.closeConnection();
      },
    });
  };

  return { fetchOrderInfo, isFetching, error, orderInfoResponse };
}

import Popover from "@amzn/awsui-components-react/polaris/popover";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Box from "@amzn/awsui-components-react/polaris/box";
import { ReactProps } from "Types";

interface TooltipProps extends ReactProps {
  header: string;
  position?: "top" | "bottom" | "left" | "right";
}

const Tooltip = ({ header, children, position = "top" }: TooltipProps) => {
  return (
    <Popover header={header} content={children} position={position} triggerType="custom">
      <div style={{ cursor: "pointer" }}>
        <Box margin={{ left: "xxs" }}>
          <Icon name="status-info" size="inherit" variant="subtle" />
        </Box>
      </div>
    </Popover>
  );
};

export default Tooltip;

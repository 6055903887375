import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import Form from "@amzn/awsui-components-react/polaris/form";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useQueryOrderInfo } from "../../Api/useQueryOrderInfo";
import { useState } from "react";
import Input from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import OrderDetailPageResult from "./orderDetailPageResult";
import { onEnterClicked } from "../../utils/keyboardEventUtils";

const OrderDetailPage = () => {
  const [inputId, setInputId] = useState<string>("");
  const { fetchOrderInfo, isFetching, error, orderInfoResponse } = useQueryOrderInfo();

  const handleInputIdChanged = ({ detail }: { detail: { value: string } }) => {
    setInputId(detail.value);
  };

  const handleOrderInfoButtonClicked = () => {
    fetchOrderInfo(inputId);
  };

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={<Header variant="h1">Display IBA Order Details</Header>}
          actions={
            <Button variant="primary" loading={isFetching} onClick={handleOrderInfoButtonClicked}>
              Show Information
            </Button>
          }
          errorText={error}
        >
          <FormField label="IBA Order" stretch>
            <Input
              value={inputId}
              onChange={handleInputIdChanged}
              onKeyUp={onEnterClicked(handleOrderInfoButtonClicked)}
              autoFocus
              placeholder="Enter Order ID"
            />
          </FormField>
        </Form>

        {orderInfoResponse && <OrderDetailPageResult response={orderInfoResponse} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};
export default OrderDetailPage;

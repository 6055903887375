import { Grid } from "@amzn/awsui-components-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { Order, OrderDetails } from "../../Types/OrderInfoTypes/OrderTypes";
import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { IBAOrderEntityType, OrderDetailsTable } from "../../components/OrderInfoComponents/IBAOrderDetailsTable";

interface OrderDetailResponseItem {
  id: React.ReactNode;
  refOrder: string;
  auditStatus: string;
  links: string;
}

const OrderDetailPageResult = ({ response }: { response: OrderDetails }) => {
  if (response.purchase == null) {
    return <Alert>{response.inputId} is not a valid input.</Alert>;
  }

  const { purchase } = response;
  let orderEntity: IBAOrderEntityType = null;
  let kairosIsActivated: boolean = false;
  let paymentMethodCode: string[] | null = null;

  const items: OrderDetailResponseItem[] = [
    {
      id: purchase.purchaseId,
      refOrder: "",
      auditStatus: "",
      links: "",
    },
  ];

  const addOrdersSection = () => {
    items.push({
      id: "Orders",
      refOrder: "",
      auditStatus: "",
      links: "",
    });

    purchase.ordersList.forEach((order) => {
      items.push({
        id: (
          <>
            {order.orderId} {<b>{order.orderType}</b>}
          </>
        ),
        refOrder: order.refOrderIdList?.join(", "),
        auditStatus: order.workflowAuditState,
        links: "",
      });

      updateOrderEntity(order);
    });
  };

  const addShipmentsSection = () => {
    items.push({
      id: "Shipment",
      refOrder: "",
      auditStatus: "",
      links: "",
    });

    purchase.ordersList.forEach((order) => {
      order.shipmentsList.forEach((shipment) => {
        items.push({
          id: shipment.shipmentId,
          refOrder: order.orderId,
          auditStatus: shipment.workflowAuditStatus,
          links: "",
        });

        updateOrderEntity(order);
      });
    });
  };

  const addReversalsSection = () => {
    items.push({
      id: "Reversals",
      refOrder: "",
      auditStatus: "",
      links: "",
    });

    purchase.ordersList.forEach((order) => {
      order.reversalsList.forEach((reversal) => {
        items.push({
          id: reversal.reversalId,
          refOrder: order.orderId,
          auditStatus: reversal.workflowAuditStatus,
          links: "",
        });

        updateOrderEntity(order);
      });
    });
  };

  const updateOrderEntity = (order: Order) => {
    if (!orderEntity) {
      if (order.orderType === "CFO") {
        orderEntity = order.ibaOrderEntityDetails;
      } else if (order.orderType === "SFO") {
        orderEntity = order.multiCompanyOrderingDataDetails;
      } else {
        orderEntity = order.ibaOrderIneligibilityDetails;
      }
      kairosIsActivated = order.kairosIsActivated;
      paymentMethodCode = order.paymentMethodCodeList;
    }
  };

  if (purchase.ordersList.length > 0) {
    addOrdersSection();
    addShipmentsSection();
    addReversalsSection();
  }

  return (
    <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
      <Table
        columnDefinitions={[
          {
            id: "ids",
            header: "Purchase",
            cell: (item) => item.id,
          },
          {
            id: "refOrder",
            header: "Corresponding order",
            cell: (item) => item.refOrder,
          },
          {
            id: "auditStatus",
            header: "Monitoring status",
            cell: (item) => auditStatus(item.auditStatus),
          },
          {
            id: "links",
            header: "Links",
            cell: (item) => item.links,
          },
        ]}
        items={items}
        loadingText="Loading resources"
        resizableColumns
        sortingDisabled
        variant="embedded"
        wrapLines
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No purchase, order, shipment, or reversal with this id exists!</b>
            </SpaceBetween>
          </Box>
        }
      />

      <Box variant="awsui-key-label">
        <OrderDetailsTable
          ibaOrderEntity={orderEntity}
          purchase={purchase}
          kairos={kairosIsActivated}
          paymentMethodCode={paymentMethodCode}
        />
      </Box>
    </Grid>
  );
};
export default OrderDetailPageResult;

const auditStatus = (auditStatus: string) => {
  if (auditStatus && auditStatus.includes("Error")) {
    return <Box color="text-status-error">{auditStatus}</Box>;
  }
  return <Box color="text-status-success">{auditStatus}</Box>;
};

import { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { type NonCancelableCustomEvent } from "@amzn/awsui-components-react";

export function onEnterClicked(callback: () => void) {
  return (event: NonCancelableCustomEvent<InputProps.KeyDetail>) => {
    if (event.detail.key === "Enter") {
      callback();
    }
  };
}

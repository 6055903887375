import { useSearchParams } from "react-router-dom";

const ORDER_ID_SEARCH_PARAM = "orderId";

const useCfoSfoMappingSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get(ORDER_ID_SEARCH_PARAM);
  const cfoSfoMappingSearchParams = { orderId };

  const setCfoSfoMappingSearchParams = ({ orderId }: { orderId: string }) => {
    const newSearchParams = { [ORDER_ID_SEARCH_PARAM]: orderId };
    setSearchParams(newSearchParams, { replace: true });
  };

  return { cfoSfoMappingSearchParams, setCfoSfoMappingSearchParams };
};

export default useCfoSfoMappingSearchParams;

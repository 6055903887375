import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import {
  IBAOrderEntityDetails,
  IBAOrderIneligibilityDetails,
  MultiCompanyOrderingDataDetails,
  Purchase,
} from "../../Types/OrderInfoTypes/OrderTypes";

interface OrderTableItem {
  field: string;
  value: string | boolean | number;
}

export type IBAOrderEntityType =
  | IBAOrderEntityDetails
  | IBAOrderIneligibilityDetails
  | MultiCompanyOrderingDataDetails
  | null;

export const OrderDetailsTable: React.FC<{
  ibaOrderEntity: IBAOrderEntityType;
  purchase: Purchase;
  kairos: boolean;
  paymentMethodCode: string[] | null;
}> = ({ ibaOrderEntity, purchase, kairos, paymentMethodCode }) => {
  const orderItems: OrderTableItem[] = ibaOrderEntity
    ? Object.entries(ibaOrderEntity).map(([field, value]) => ({ field, value }))
    : [];

  const purchaseItems: OrderTableItem[] = [
    { field: "Ship to", value: purchase.shipTo },
    { field: "Date and Time", value: purchase.dateAndTimeOfPurchase },
    { field: "Kairos", value: kairos },
    { field: "Payment Methods", value: paymentMethodCode?.join(", ") ?? "-" },
  ];

  return (
    <Box>
      <Table
        header={<Box>Purchase Details</Box>}
        columnDefinitions={[
          { header: "Field", cell: (item: OrderTableItem) => <Box variant="small">{item.field}</Box> },
          {
            header: "Value",
            cell: (item: OrderTableItem) => <Box variant="small">{String(item.value)}</Box>,
          },
        ]}
        items={purchaseItems}
      />

      {ibaOrderEntity && (
        <Table
          header={<Box>Order Details</Box>}
          columnDefinitions={[
            { header: "Field", cell: (item: OrderTableItem) => <Box variant="small">{item.field}</Box> },
            {
              header: "Value",
              cell: (item: OrderTableItem) => <Box variant="small">{String(item.value)}</Box>,
            },
          ]}
          items={orderItems}
        />
      )}
    </Box>
  );
};

import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import Form from "@amzn/awsui-components-react/polaris/form";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useState } from "react";
import useQueryCfoSfoMappings from "Api/useQueryCfoSfoMappings";
import useCfoSfoMappingSearchParams from "./useCfoSfoMappingSearchParams";
import CfoSfoMappingsPageResult from "./cfoSfoMappingsPageResult";
import { onEnterClicked } from "../../utils/keyboardEventUtils";

const CfoSfoMappingsPage = () => {
  const { cfoSfoMappingSearchParams, setCfoSfoMappingSearchParams } = useCfoSfoMappingSearchParams();

  const [orderId, setOrderId] = useState(cfoSfoMappingSearchParams.orderId ?? "");

  const handleOrderIdChanged = ({ detail }: { detail: InputProps.ChangeDetail }) => setOrderId(detail.value);

  const { isFetching, error, data: cfoSfoMappingsResponse, refetch } = useQueryCfoSfoMappings({ orderId });

  const handleFindMappingButtonClicked = () => {
    setCfoSfoMappingSearchParams({ orderId });
    refetch();
  };

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={<Header variant="h1">Find CFO SFO mappings</Header>}
          actions={
            <Button variant="primary" loading={isFetching} onClick={handleFindMappingButtonClicked}>
              Find Mapping
            </Button>
          }
          errorText={error}
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="CFO or SFO ID" stretch>
              <Input
                value={orderId}
                onChange={handleOrderIdChanged}
                onKeyUp={onEnterClicked(handleFindMappingButtonClicked)}
                autoFocus
                placeholder="Enter CFO or SFO ID"
              />
            </FormField>
          </SpaceBetween>
        </Form>

        {cfoSfoMappingsResponse && <CfoSfoMappingsPageResult response={cfoSfoMappingsResponse} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};
export default CfoSfoMappingsPage;

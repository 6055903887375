import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import ValueWithLabel from "components/ValueWithLabel";
import Tooltip from "components/Tooltip";

interface BusinessStatusCurrentProps {
  readonly enrolled: boolean;
  readonly denylisted: boolean;
  readonly vettedMarketplaces: string[];
}

const boolToStr = (bool: boolean) => (bool ? "True" : "False");
const marketplacesToStr = (marketplaces: string[]): string => {
  return marketplaces.length === 0 ? "N/A" : marketplaces.join("/");
};

const BusinessStatusCurrent = ({ enrolled, denylisted, vettedMarketplaces }: BusinessStatusCurrentProps) => {
  return (
    <Container header={<Header variant="h2">Current Status</Header>}>
      <ColumnLayout columns={3}>
        <ValueWithLabel label="Enrolled">{boolToStr(enrolled)}</ValueWithLabel>
        <div>
          Vetted Marketplace
          <Tooltip header="Vetted Marketplace">
            Marketplaces where the business has been vetted and can use IBA.
            <br />
            <br />
            N/A: Business not enrolled.
          </Tooltip>
          <div>{marketplacesToStr(vettedMarketplaces)}</div>
        </div>
        <ValueWithLabel label="Denylisted">{boolToStr(denylisted)}</ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};

export default BusinessStatusCurrent;
